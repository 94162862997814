<template>
  <b-container fluid>
    <sheet-file-management-form
      class="custom-card-size"
      :onSubmitHandler="onSubmit"
    />
    <success-modal
      ref="success-modal"
      description="Arquivo enviado com sucesso."
      buttonText="Ok, entendi!"
    />
  </b-container>
</template>

<script>
import { sheetUpload } from "../../../../services/document-upload/document-file-service";
import { inject } from "@vue/composition-api";
import SheetFileManagementForm from "../forms/SheetFileManagementForm.vue";
import { createToast } from "../../../../components/toast/toast";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";

export default {
  name: "sheet-file-tab",
  components: {
    SheetFileManagementForm,
    SuccessModal,
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onSubmit(formData) {
      try {
        this.globalLoading = true;
        await sheetUpload(formData);
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.globalLoading = false;
      } catch (error) {
        this.globalLoading = false;
        console.error("Error OnSubmit", error);
        createToast("Atenção", "Ocorreu um erro ao processar o arquivo.");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-card-size {
  width: 100%;
}
</style>
